<template>
  <div class="achie">
    <Head></Head>
    <div class="select">
      <div class="achievements-bg">
        <div class="achievements-cont0">
          <div class="select-p">
            <div
              class="select-item"
              v-for="(item, index) in itemList"
              :key="index"
              @click="goSelect(item)"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="achievements-cont1" @click="goDetail('/prize')">
          <!-- <a href="#">
            <img src="@/image/achievements/select-2.png" alt="" />
          </a> -->
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
export default {
  name: "achievements",
  data() {
    return {
      isFlag: false,
      itemList: [
        { name: "领导关怀", value: "1" },
        { name: "省区交流", value: "2" },
        { name: "部门合作", value: "3" },
        { name: "平台共建", value: "4" },
      ],
    };
  },
  components: { Head, Foot },
  mounted() {
    setTimeout(() => {
      this.isFlag = !this.isFlag;
    }, 300);
  },
  methods: {
    goHome: function () {
      this.$router.push({ path: "/" });
    },
    goSelect(item) {
      this.$router.push({ path: "/renyuan", query: { id: item.value } });
    },
    goDetail: function (route) {
      this.$router.push({ path: route });
    },
  },
};
</script>

<style lang="scss" scoped>
.achie {
  height: 100vh;
  background: url("../../image/achievements/bg_02.jpg") center no-repeat;
  background-size: 100% 100%;
}
.select {
  height: 85vh;
  padding: 7vh 3vw;
}
.select-p {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  margin-top: 30vh;
  padding: 0 2em;
}
.select-item {
  cursor: pointer;
  padding: 4vh 1vw;
  text-align: center;
  background: #163785;
  opacity: 0.88;
  border: 1px solid #5278a6;
  color: #fff;
  font-size: 2vw;
  border-radius: 14px;
  font-weight: bold;
}
.achievements-bg {
  display: flex;
  justify-content: space-around;
  // align-items: center;
}

.achievements-cont0,
.achievements-cont1 {
  width: 43vw;
  height: 72vh;
  border-radius: 10px;
}
.achievements-cont0 {
  background: url("../../image/achievements/select-1.png") center no-repeat;
  background-size: 100% 100%;
}
.achievements-cont1 {
  background: url("../../image/achievements/select-2.png") center no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.achievements-cont img {
  border-radius: 10px;
  // width: 100%;
  // height: 100%;
}
.select-item:hover,
.achievements-cont1:hover {
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
}
.select-item:hover,
.achievements-cont1:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);

  -webkit-box-shadow: 0 58px 36px -56px black;
  -moz-box-shadow: 0 58px 36px -56px black;
  box-shadow: 0 58px 36px -56px black;
}
</style>
